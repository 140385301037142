export const BAR_HEIGHT_NUM = 70;
export const BAR_HEIGHT = `${BAR_HEIGHT_NUM}px`;
export const TOP_BAR_INDEX = 100;

export const palette = {
  accent: '#41A2E9',
  primary: '#fff',
  secondary: '#f7f9fa',
  sticker: '#fff2ac',
  text: {
    primary: '#0f0f0f',
    secondary: '#fff',
    disabled: '#b8b6b6',
  },
  success: '#47E4A2',
  error: '#FF5468',
};
const PRIMARY_FONT = "'Manrope'";
