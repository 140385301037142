import { Progress, Stack } from "@chakra-ui/react";
import React from "react";
import Logo from "../components/Logo";

const LoadingScreen = () => {
  return (
    <Stack
      justifyContent="center"
      direction="column"
      alignItems="center"
      w="100vw"
      h="100vh"
    >
      <Logo width={230} />
      <div style={{ marginTop: "3rem" }}>
        <Progress w="40vw" size="xs" isIndeterminate borderRadius="5px" />
      </div>
    </Stack>
  );
};

export default LoadingScreen;
