import React from "react";
import { useNavigate } from "react-router";
import logo from "../assets/logo.png";

const Logo = ({ width = 152 }) => {
  const navigate = useNavigate();
  return (
    <button onClick={() => navigate("/")}>
      <img alt="Smartbox" src={logo} width={width} height={22} />
    </button>
  );
};

export default Logo;
