import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';

interface State {
  zoom: number;
}

const INIT: State = {
  zoom: 1,
};

const addZoomAction: CaseReducer<State, PayloadAction<number>> = (
  state,
  action,
) => {
  const newZoom = state.zoom + action.payload;
  state.zoom = Math.min(Math.max(newZoom, 0.5), 2);
};

const slice = createSlice({
  name: 'config',
  initialState: INIT,
  reducers: {
    addZoom: addZoomAction,
  },
});

export default slice.reducer;

export const { addZoom } = slice.actions;
