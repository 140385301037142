import { extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import { palette } from './theme';

const Button = {
  baseStyle: {
    fontWeight: 'bold',
    borderRadius: '20px',
    px: '1rem',
    minWidth: '170px',
    minW: '170px',
  },
  variants: {
    login: {
      bgColor: '#41A2E9',
      color: '#fff',
      borderRadius: '32px',
      h: '45px',
    },
    default: {
      bgColor: '#41A2E9',
      color: '#fff',
      borderRadius: '5px',
      h: '30px',
    },
    delete: {
      bgColor: '#ff3636',
      color: '#fff',
      borderRadius: '5px',
      h: '30px',
    },
  },
  defaultProps: { colorScheme: 'blue', minW: '170px' },
};

const IconButton = {
  baseStyle: {
    fontSize: '25px',
    padding: '1rem',
    borderColor: 'gray.100',
    borderWidth: 2,
  },
  variants: {},
  defaultProps: { borderColor: 'gray.500', color: 'black' },
};

const Input = {
  baseStyle: {
    height: '100%',
    // bg: '#F8FAFC',
  },
  variants: {
    smooth: {
      boxShadow: 'md',
      backgroundColor: 'gray.900',
    },
    login: {
      bgColor: '#000',
    },
  },
};

const Checkbox = {
  baseStyle: {
    colorScheme: 'gray.100',
  },
};

const styles = {
  global: (props) => ({
    body: {
      bg: mode('#fff', 'gray.900')(props),
      color: mode('black', 'white')(props),
      fontFamily: 'Manrope',
      variant: 'solid',
    },
  }),
};

const components = { Button, IconButton, Input, Checkbox };

const colors = {
  gray: { 100: '#F8FAFB', 900: '#282D30' },
  accent: {
    500: '#41A2E9',
  },
  dark: {
    500: '#000',
  },
};

const theme = extendTheme({
  styles,
  colors,
  components,
  fonts: {
    heading: 'Manrope',
    body: 'Manrope',
  },
});

export default theme;
