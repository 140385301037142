import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { Message } from "../types/api/chat";

interface Chats {
  [key: string]: Message[];
}
interface BoardChats {
  [key: string]: Chats;
}
interface State {
  chats: BoardChats;
}

const INIT: State = {
  chats: {},
};

const setBoardMessagesAction: CaseReducer<
  State,
  PayloadAction<{
    boardId: string;
    userId: string | "group";
    messages: Message[];
  }>
> = (state, action) => {
  const { boardId, userId, messages } = action.payload;
  _.setWith(state, `chats.${boardId}.${userId}`, messages, Object);
};

const addBoardMessagesAction: CaseReducer<
  State,
  PayloadAction<{
    boardId: string;
    userId: string | "group";
    messages: Message[];
  }>
> = (state, action) => {
  const { boardId, userId, messages } = action.payload;
  const chatPath = `chats.${boardId}.${userId}`;
  const prev = _.get(state, chatPath, []);
  _.setWith(state, chatPath, [...prev, ...messages], Object);
};
const addPrevMessagesAction: CaseReducer<
  State,
  PayloadAction<{
    boardId: string;
    userId: string | "group";
    messages: Message[];
  }>
> = (state, action) => {
  const { boardId, userId, messages } = action.payload;
  const chatPath = `chats.${boardId}.${userId}`;
  const prev = _.get(state, chatPath, []);
  _.setWith(state, chatPath, [...messages, ...prev], Object);
};

const slice = createSlice({
  name: "chat",
  initialState: INIT,
  reducers: {
    setBoardMessages: setBoardMessagesAction,
    addBoardMessages: addBoardMessagesAction,
    addPrevMessages: addPrevMessagesAction,
  },
});

export default slice.reducer;

export const { setBoardMessages, addBoardMessages, addPrevMessages } =
  slice.actions;
