import { configureStore } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import reducer, { rootReducer } from "./reducers";

const persistedReducer = persistReducer({ key: "root", storage }, reducer);

const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const defaultMiddleware = getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    });

    if (isDev) return [...defaultMiddleware, createLogger()];
    return [...defaultMiddleware];
  },
});
export const mockStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    const defaultMiddleware = getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    });

    return [...defaultMiddleware];
  },
});
export const persistedStore = persistStore(store);
export type RootState = ReturnType<typeof mockStore.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
