import React from "react";
import ReactDOM from "react-dom";
import loadable from "@loadable/component";
import "./index.css";
// import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import "./theme.scss";
import { Provider } from "react-redux";
import store, { persistedStore } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { ChakraProvider, CircularProgress } from "@chakra-ui/react";
import { CookiesProvider } from "react-cookie";
import theme from "./chakra.theme";
import { QueryClientProvider, QueryClient } from "react-query";
import LoadingScreen from "./Board/LoadingScren";
const App = loadable(() => import("./App"));

const queryClient = new QueryClient();

const Loading = () => {
  return (
    <div>
      <CircularProgress />
    </div>
  );
};

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <PersistGate loading={<LoadingScreen />} persistor={persistedStore}>
          <ChakraProvider theme={theme}>
            <CookiesProvider>
              <App />
            </CookiesProvider>
          </ChakraProvider>
        </PersistGate>
      </QueryClientProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
