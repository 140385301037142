import _ from "lodash";
import { combineReducers } from "redux";

import app from "./app";
import config from "./config";
import chat from "./chat";
export const rootReducer = combineReducers({ app, config, chat });

export const LOGOUT_USER = "LOGOUT_USER";
export const SET_CACHE = "SET_CACHE";

export default (state, action) => {
  if (action.type === LOGOUT_USER) return rootReducer(undefined, action);
  if (action.type === SET_CACHE) {
    const { payload } = action;
    const { newData, path } = payload;
    // Only works copying state by parsing JSON, other solutions don t work
    // probably immer from reduxjs toolkit causing the issue
    const copiedState = JSON.parse(JSON.stringify(state));

    const newState = _.setWith(copiedState, path, newData, Object);

    return rootReducer(newState, action);
  }

  return rootReducer(state, action);
};
