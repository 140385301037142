import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { LoggedUser, User } from "../types/api";
import { BoardEvent, Facilitator } from "../types/api/board";
import { UserType } from "../types/api/user";

interface State {
  boards: {
    [key: string]: {
      userType: UserType;
      currentEvent: BoardEvent;
      events: BoardEvent[];
      selectedUser?: User | null;
      owner: Facilitator;
    };
  };
  user: null | LoggedUser;
}

const INIT: State = {
  // Permissions by board
  boards: {},
  user: null,
};

const setCurrentEventAction: CaseReducer<
  State,
  PayloadAction<{ event: BoardEvent; boardId: string }>
> = (state, action) => {
  const { boardId, event } = action.payload;

  _.set(state, `boards[${boardId}].currentEvent`, event);
};

const setUserTypeAction: CaseReducer<
  State,
  PayloadAction<{ boardId: string; userType: UserType }>
> = (state, action) => {
  const { boardId, userType } = action.payload;

  _.set(state, `boards[${boardId}].userType`, userType);
};
const setBoardDataAction: CaseReducer<
  State,
  PayloadAction<{
    boardId: string;
    data: { userType?: UserType; owner?: Facilitator };
  }>
> = (state, action) => {
  const { boardId, data } = action.payload;
  const prevBoardData = _.get(state, `boards[${boardId}]`, {});
  _.set(state, `boards[${boardId}]`, { ...prevBoardData, ...data });
};
const setUserBoxFilterAction: CaseReducer<
  State,
  PayloadAction<{ user: User | null; boardId: string }>
> = (state, action) => {
  const { user, boardId } = action.payload;

  _.set(state, `boards[${boardId}].selectedUser`, user);
};

const setUserAction: CaseReducer<
  State,
  PayloadAction<{ user: LoggedUser | null }>
> = (state, action) => {
  const { user } = action.payload;
  state.user = user;
};

const slice = createSlice({
  name: "app",
  initialState: INIT,
  reducers: {
    setUserType: setUserTypeAction,
    setCurrentEvent: setCurrentEventAction,
    setUser: setUserAction,
    setUserBoxFilter: setUserBoxFilterAction,
    setBoardData: setBoardDataAction,
  },
});

export default slice.reducer;

export const {
  setCurrentEvent,
  setUserType,
  setUser,
  setUserBoxFilter,
  setBoardData,
} = slice.actions;

export const pathBoard = (boardId: string) => `[app][boards][${boardId}]`;
export const pathBoardEvents = (boardId: string) =>
  `[app][boards][${boardId}][events]`;
export const pathBoardCurrentEvent = (boardId: string) =>
  `[app][boards][${boardId}][currentEvent]`;
